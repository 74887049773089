import React, { Component } from "react";
import "../secondarystyles.css";

export default class Schedule extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <h2>Scrimmages</h2>
        </div>
        <div className="card_centerer">
          <table className="schedule">
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Teams</th>
              <th>Opponent</th>
              <th>Site</th>
              <th>Times</th>
              <th>Field</th>
            </tr>
            <tr>
              <td>Dec 8</td>
              <td>Fri</td>
              <td>JV/V</td>
              <td>Kilgore</td>
              <td>Home</td>
              <td>5/6:30</td>
              <td>SoccerComplex</td>
            </tr>
            <tr>
              <td>Dec 11</td>
              <td>Mon</td>
              <td>JVA</td>
              <td>Legacy/Nac</td>
              <td>Away</td>
              <td>TBA</td>
              <td>Legacy</td>
            </tr>
            <tr>
              <td>Dec 12</td>
              <td>Tue</td>
              <td>Varsity Only</td>
              <td>Legacy/Nac</td>
              <td>Away</td>
              <td>TBA</td>
              <td>Legacy</td>
            </tr>
            <tr>
              <td>Dec 13</td>
              <td>Wed</td>
              <td>JVB</td>
              <td>Legacy</td>
              <td>Home</td>
              <td>5:30</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Dec 15</td>
              <td>Fri</td>
              <td>JV/V</td>
              <td>Athens</td>
              <td>Home</td>
              <td>4:15/6</td>
              <td>Soccer Complex</td>
            </tr>

          </table>
        </div>
        <div className="card_centerer">
          <h2>Pre Season</h2>
        </div>
        <div className="card_centerer">
          <table className="schedule">
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Teams</th>
              <th>Opponent</th>
              <th>Site</th>
              <th>Times</th>
              <th>Field</th>
            </tr>
            
            <tr>
              <td>Jan 2</td>
              <td>Tue</td>
              <td>JVB/JVA/Var</td>
              <td>Mesquite Horn</td>
              <td>Home</td>
              <td>12/1:30/3</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Jan 4</td>
              <td>Thur</td>
              <td>JV/V</td>
              <td>Forney Tournament</td>
              <td>Away</td>
              <td>1:30/12&4:30</td>
              <td>Forney</td>
            </tr>
            <tr>
              <td>Jan 5</td>
              <td>Fri</td>
              <td>JV/V</td>
              <td>Forney Tournament</td>
              <td>Away</td>
              <td>12:15&3/10:30</td>
              <td>Forney</td>
            </tr>
            <tr>
              <td>Jan 6</td>
              <td>Sat</td>
              <td>JV/V</td>
              <td>Forney Tournament</td>
              <td>Away</td>
              <td>TBA</td>
              <td>Forney</td>
            </tr>
            <tr>
              <td>Jan 11</td>
              <td>Thur</td>
              <td>JVB</td>
              <td>Nacogdoches</td>
              <td>Home</td>
              <td>9:00AM</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Jan 11</td>
              <td>Thur</td>
              <td>JV/V</td>
              <td>Longview Tournament</td>
              <td>Home</td>
              <td>4/6pm</td>
              <td>Lobo Stadium</td>
            </tr>
            <tr>
              <td>Jan 12</td>
              <td>Fri</td>
              <td>JVB</td>
              <td>Tyler Legacy</td>
              <td>Home</td>
              <td>9:00AM</td>
              <td>Soccer Coimplex</td>
            </tr>
            <tr>
              <td>Jan 12</td>
              <td>Fri</td>
              <td>JV/V</td>
              <td>Longview Tournament</td>
              <td>Home</td>
              <td>12/2pm</td>
              <td>Lobo Stadium</td>
            </tr>
            <tr>
              <td>Jan 13</td>
              <td>Sat</td>
              <td>JV/V</td>
              <td>Longview Tournament</td>
              <td>Home</td>
              <td>8:30am</td>
              <td>Stadium/Grass</td>
            </tr>
            <tr>
              <td>Jan 16</td>
              <td>Tue</td>
              <td>JVB/JVA/Var</td>
              <td>Mesquite</td>
              <td>Away</td>
              <td>4:15/5:30/7</td>
              <td>Hanby Stadium</td>
            </tr>
            <tr>
              <td>Jan 19</td>
              <td>Fri</td>
              <td>JVB/JVA/Var</td>
              <td>Henderson</td>
              <td>Away</td>
              <td>4:15/5:30/7</td>
              <td>Henderson</td>
            </tr>

          </table>
        </div>

        <div className="card_centerer">
          <h2>District</h2>
        </div>
        <div className="card_centerer">
          <table className="schedule">
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Teams</th>
              <th>Opponent</th>
              <th>Site</th>
              <th>Times</th>
              <th>Field</th>
            </tr>
            <tr>
              <td>Jan 26</td>       
              <td>Fri</td>
              <td>JVB/JVA/Var</td>  
              <td>John Tyler</td>   
              <td>Away</td>
              <td>4:15/5:30/7pm</td>
              <td>Rose Stadium</td> 
            </tr>
            <tr>
              <td>Jan 30</td>       
              <td>Tue</td>
              <td>JV/Var</td>
              <td>Texas High</td>
              <td>Away</td>
              <td>5:30-7pm</td>
              <td>Texarkana</td>
            </tr>
            <tr>
              <td>Feb 2</td>
              <td>Fri</td>
              <td>JV/Var</td>
              <td>Hallsville</td>
              <td>Home</td>
              <td>5:30-7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Feb 5</td>
              <td>Mon</td>
              <td>JV/Var</td>
              <td>Marshall</td>
              <td>Away</td>
              <td>5:30-7pm</td>
              <td>Marshall</td>
            </tr>
            <tr>
              <td>Feb 10</td>
              <td>Sat</td>
              <td>JV/Var</td>
              <td>Pinetree (middle school day)</td>
              <td>Home</td>
              <td>12:30/2pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Feb 13</td>
              <td>Tue</td>
              <td>JV/Var</td>
              <td>Whitehouse</td>
              <td>Away</td>
              <td>5:30-7pm</td>
              <td>Whitehouse</td>
            </tr>
            <tr>
              <td>Feb 16</td>
              <td>Fri</td>
              <td>JVB/JVA/Var</td>
              <td>Mt. Pleasant</td>
              <td>Away</td>
              <td>4:15/5:30/7pm</td>
              <td>Mt. Pleasant</td>
            </tr>
            <tr>
              <td>Feb 20</td>
              <td>Tue</td>
              <td>JVB/JVA/Var</td>
              <td>John Tyler</td>
              <td>Home</td>
              <td>4:15/5:30/7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Feb 23</td>
              <td>Fri</td>
              <td>JV/Var</td>
              <td>Texas High</td>
              <td>Home</td>
              <td>5:30-7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Feb 27</td>
              <td>Tue</td>
              <td>JV/Var</td>
              <td>Hallsville</td>
              <td>Away</td>
              <td>5:30-7pm</td>
              <td>Hallsville</td>
            </tr>
            <tr>
              <td>Mar 1</td>
              <td>Fri</td>
              <td>JV/Var</td>
              <td>Marhsall</td>
              <td>Home</td>
              <td>5:30-7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Mar 5</td>
              <td>Tue</td>
              <td>JV/Var</td>
              <td>Pinetree</td>
              <td>Away</td>
              <td>5:30-7pm</td>
              <td>PineTree</td>
            </tr>
            <tr>
              <td>Mar 8</td>
              <td>Fri</td>
              <td>JV/Var</td>
              <td>Whitehouse(Senior Nite)</td>
              <td>Home</td>
              <td>5:30-7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Mar 20</td>
              <td>Wed</td>
              <td>JVB/JVA/Var</td>
              <td>Mt. Pleasant</td>
              <td>Home</td>
              <td>4:15/5:30/7pm</td>
              <td>Soccer Complex</td>
            </tr>
            <tr>
              <td>Mar 23</td>
              <td>Sat</td>
              <td>Varsity</td>
              <td>District Certification</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>
            <tr>
              <td>25-26th</td>
              <td> </td>
              <td>Varsity</td>
              <td>BIDistrict</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>
            <tr>
              <td>28-30th</td>
              <td> </td>
              <td>Varsity</td>
              <td>Area</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>
            <tr>
              <td>April 1-2</td>
              <td> </td>
              <td>Varsity</td>
              <td>Regional Quarterfinals</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>
            <tr>
              <td>April 5-6</td>
              <td> </td>
              <td>Varsity</td>
              <td>Regional Tournament</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>
            <tr>
              <td>10-13th</td>
              <td> </td>
              <td>Varsity</td>
              <td>State Tournament</td>
              <td>TBA</td>
              <td>TBA</td>
              <td>TBA</td>
            </tr>

          </table>
        </div>
      </section>
    );
  }
}
