import "../Buy/Buy.css";

function Donate() {
  return (
    <div className="donatecard">
      <h2>Donate</h2>
      <p>
        Donating to the Longview Lobos Soccer Team directly helps fund soccer
        expenses like new uniforms, tournaments and playoffs, meals,
        transportation, etc. Things not covered by LISD.
      </p>
      <p>Donating is a great way to have a direct impact on the team and to give back to the students.</p>
      <p>We are a non-profit, so it is a tax deduction.</p>
      <div className="center_donate_text">
        <p>
          <b>Send postal checks to:</b>
        </p>
        <p>Lobo Soccer Booster Club</p>
        <p>ATTN: Kim Hardison</p>
        <p>303. W. Loop 281</p>
        <p>Suite, 110, # 114</p>
        <p>Longview, TX, 75605</p>
      </div>
    </div>
  );
}
export default Donate;
