import React, { Component } from "react";

export default class Photos extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              Note: We have done our best to gather and compile pictures from
              all years from all sources, however, if you have color pictures
              you can send us, so that we can update the photo section to make
              it more accurate, please email tkelsey@hotmail.com.
            </p>
          </div>
        </div>
        <div className="card_centerer">
          <h2>2022</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2022/2022varsity.jpg" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/JVTeam.jpg" alt="" />
          </div>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Seniors</b>
            </p>
            <img src="/Images/2022/2022seniors.jpg" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b>Sophomores</b>
            </p>
            <img src="/Images/2022/2022sophomores.jpg" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2021</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/IMG_4239.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2021/IMG_4240.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2021/DSC_0754.JPG" alt="" />
          </div> */}

          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2021/2021jvgirls.jpg" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2020</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2020/2020varsityboys.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2020/2020jvboys.jpg" alt="" />
          </div>
        </div>

        {/* <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2020/2020  Team Girls.png" alt="" />
          </div>
        </div> */}

        <div className="card_centerer">
          <h2>2019</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>District Champs</b>
            </p>
            <img src="/Images/2019/new1.jpg" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2019/2019  Team.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2019/2019 V2.jpg" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2019/2019  Team 8.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2018</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2018/2018 V.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2018/new2.jpg" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2018/IMG_E4260.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2018/IMG_E4261.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2017</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2017/DSC_0758.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2017/DSC_0759.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2017/IMG_4267.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2017/DSC_0762.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2016</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2016/DSC_0767.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2016/JV.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2016/DSC_0765.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2016/DSC_0766.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2015</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img
              src="/Images/2015/Screenshot 2022-07-27 at 16-49-41 KPP_8823.png"
              alt=""
            />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img
              src="/Images/2015/Screenshot 2022-07-27 at 16-50-11 KPP_8831.png"
              alt=""
            />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2015/IMG_4272.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2015/IMG_4273.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2014</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2014/201 Team Photo.png" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2014/2014 Team.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2014/2014 Team 3.png" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2014/2014 Team 4.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2013</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2013/2013 Varsity.png" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2013/2013 JV.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2013/2013 Team 4.png" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b>Seniors</b>
            </p>
            <img src="/Images/2013/2013 Team 3.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2012</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2012/2012.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2011</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2011/2011.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2011/2011 Team.png" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>2010</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2010/2010.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2010/DSC_0733.JPG" alt="" />
          </div>
        </div>

        {/* <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2010/DSC_0735.JPG" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2010/DSC_0739.JPG" alt="" />
          </div>
        </div> */}

        <div className="card_centerer">
          <h2>2009</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2009/Lobo Varsity 2008-09.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2009/Lobo JV 2008-09.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2009/DSC_0743.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2009/DSC_0744.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2008</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2008/2008 correct.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2008/Lobo JV 2007-08.jpg" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2008/DSC_0750.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2008/DSC_0749.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2007</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2007/IMG_E4228.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2007/IMG_E4226.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2007/IMG_E4230.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2007/IMG_4229.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2006</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2006/2006 Varsity.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>Plaque</b>
            </p>
            <img src="/Images/2006/2006 Plaque.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2006/DSC_0723.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2006/DSC_0724.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2005</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2005/2005.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2005/IMG_4221.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2005/IMG_4219.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2005/IMG_4217.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2004</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2004/IMG_E4249.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2004/DSC_0677.JPG" alt="" />
          </div>
        </div>

        {/* <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2004/IMG_4214.JPG" alt="" />
          </div>
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/2004/IMG_4215.JPG" alt="" />
          </div>
        </div> */}

        <div className="card_centerer">
          <h2>2003</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2003/2003 correct.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2003/DSC_0685.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2003/DSC_0682.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2003/DSC_0683.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2002</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2002/DSC_0675.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2002/DSC_0674.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2002/IMG_4207.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2002/IMG_4205.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2001</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/2001/IMG_4202.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2001/DSC_0665.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2001/DSC_0663.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>2000</h2>
        </div>
        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/2000/DSC_0668.JPG" alt="" />
          </div> */}

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/2000/DSC_0671.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2000/DSC_0669.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/2000/DSC_0672.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1999</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1999/IMG_4193.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1999/IMG_4194.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1999/IMG_4195.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/1999/IMG_4197.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1998</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1998/IMG_4186.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1998/IMG_4188.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1998/IMG_4190.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/1998/IMG_4191.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1997</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1997/DSC_0699.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1997/DSC_0701.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1997/DSC_0702.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/1997/DSC_0704.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1996</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1996/DSC_0706.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1996/IMG_4183.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1996/DSC_0709.JPG" alt="" />
          </div> */}
          {/* <div className="subtext">
            <p>
              <b>JV Girls</b>
            </p>
            <img src="/Images/1996/DSC_0710.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1995</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1995/IMG_4177.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1995/IMG_4176.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1995/IMG_4174.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1994</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1994/DSC_0771.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1994/IMG_4170.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          {/* <div className="subtext">
            <p>
              <b>Varsity Girls</b>
            </p>
            <img src="/Images/1994/DSC_0774.JPG" alt="" />
          </div> */}
        </div>

        <div className="card_centerer">
          <h2>1993</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1993/DSC_0780.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1993/DSC_0778.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1992</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1992/IMG_4167.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1992/IMG_4165.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1991</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1991/IMG_4160.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1991/DSC_0791 (2).JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1990</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>First Alumni Game</b>
            </p>
            <img src="/Images/1990/LOBOS alumni game.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1990/DSC_0795.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1990/LOBOS 1990 team.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>Roster</b>
            </p>
            <img src="/Images/1990/Roster 1990.jpg" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1989</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1989/Group Shot.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1989/DSC_0800.JPG" alt="" />
          </div>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1989/1989 District Champs.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1989/DSC_0799.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1988</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1988/DSC_0802.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>Green Team</b>
            </p>
            <img src="/Images/1988/DSC_0804.JPG" alt="" />
          </div>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>White Team</b>
            </p>
            <img src="/Images/1988/DSC_0806.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1987</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1987/Group Shot.jpg" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1987/DSC_0809.JPG" alt="" />
          </div>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1987/DSC_0808.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1986</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1986/DSC_0811.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1986/DSC_0813.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1985</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b>Varsity Boys</b>
            </p>
            <img src="/Images/1985/DSC_0815.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b>JV Boys</b>
            </p>
            <img src="/Images/1985/DSC_0816.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1984</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1984/DSC_0818.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1984/DSC_0819.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>1983</h2>
        </div>
        <div className="card_centerer">
          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1983/DSC_0821.JPG" alt="" />
          </div>

          <div className="subtext">
            <p>
              <b></b>
            </p>
            <img src="/Images/1983/DSC_0822.JPG" alt="" />
          </div>
        </div>

        <div className="card_centerer">
          <h2>Coaches</h2>
        </div>
        <div className="card_centerer">
          <img src="/Images/james/2011 Team 3.png" alt="" />
        </div>
      </section>
    );
  }
}
