import React, { Component } from "react";

export default class Socials extends Component {
  render() {
    return (
      <section className="secondarywrapper">
        <div className="card_centerer">
          <h2>Socials</h2>
        </div>
        

        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              <a href="https://twitter.com/LongviewLoboSoc">Twitter</a>
            </p>
            <p>
              <a href="https://www.facebook.com/LongviewHighSchoolLoboSoccer">
                Facebook
              </a>
            </p>
            <p>
              <a href="https://www.instagram.com/lobos.soccer/">Instagram</a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
