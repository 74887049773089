import "./Home.css";

function Home() {
  return (
    <>
      <section className="lady-lobos-banner">
        <h1>Lobos Soccer</h1>
        <img
          className="primary_background"
          src="/Images/final_background.jpg"
          alt=""
        />
      </section>

      <section id="about_section" className="about">
        <div className="card">
          <h2 className="homepagecardheader">About:</h2>
          <p>
            The Longview Lobo Boys Soccer team was started in 1983, and has
            enjoyed a rich and proud history of games against multiple local
            rivals and established Texas soccer powerhouses. In recent years,
            the Lobos have earned two decades of consecutive winning seasons,
            District Titles, and even a Regional Final. Each new season seems to
            produce a new crop of wonderful young Lobo players that get to
            experience the game of soccer at the Junior Varsity and eventually
            the varsity level and enjoy the opportunity to represent Longview's
            proud tradition of winning teams. 2019, 2020, and 2021 have seen the
            bar raised to a record high of wins, shutouts, and playoff runs. The
            coaching staff and supporters are looking forward to the new season
            with high hopes. Hard work and determination are the keys to success
            at Longview. Please come and support us and enjoy the soccer.
          </p>
        </div>
      </section>

      <section id="meet_the_team_section" className="meet_the_team">
        <div className="card">
          <h2 className="homepagecardheader">Meet the Team:</h2>
          <p style={{ margin: "20px auto auto 80px" }}>Varsity:</p>
          <img className="card1pic" src="./Images/teampic.jpg" alt="" />
          <p style={{ margin: "20px auto auto 80px" }}>Junior Varsity:</p>
          <img className="card1pic" src="./Images/JVTeam.jpg" alt="" />
          <p style={{ margin: "20px auto auto 80px" }}>Seniors:</p>
          <img
            className="card1pic"
            src="./Images/2022/2022seniors.jpg"
            alt=""
          />
        </div>
      </section>
    </>
  );
}

export default Home;
