import { useState, useEffect } from "react";

import "./Navbar.css";

function Navbar() {
  const [mobile, setMobile] = useState(false);
  const [barToggle, setBarToggle] = useState("more...");

  const [width, setWidth] = useState(window.innerWidth);

  const setDimension = () => {
    setWidth(window.innerWidth);
    if (width > 1250) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    if (width > 1180) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [width]);

  const toggle = () => {
    if (barToggle === "more...") {
      setBarToggle("less");
    } else {
      setBarToggle("more...");
    }
    console.log(barToggle);
  };

  return (
    <header className="header">
      <img className="header_icon" src="./Images/longview_logo.png" alt="" />
      <ul className="navbar_socials">
        <li>
          <a
            href="https://www.facebook.com/LongviewHighSchoolLoboSoccer"
            target="about:blank"
          >
            <img src="./Images/facebook-svg.svg" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/lobos.soccer/"
            target="about:blank"
          >
            <img src="./Images/instagram-svg.svg" alt="" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/LongviewLoboSoc" target="about:blank">
            <img src="./Images/twitter-svg.svg" alt="" />
          </a>
        </li>
      </ul>
      <div className="header_before">
        <p style={{ color: "red" }}>Follow us:</p>
      </div>
      <div className="header_container">
        <div className="header_after">
          <ul className="navbar">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/buy">Buy</a>
            </li>
            <li>
              <a href="/donate">
                <b style={{ color: "red" }}>DONATE</b>
              </a>
            </li>

            {mobile ? (
              <button className="toggler" onClick={toggle}>
                {barToggle}
              </button>
            ) : (
              <>
                <li>
                  <a href="/alumni">
                    <p>Alumni</p>
                    <p>Soccer</p>
                    <p>Records</p>
                  </a>
                </li>
                <li>
                  <a href="/photos">
                    <p>Alumni</p>
                    <p>Photos</p>
                  </a>
                </li>
                <li>
                  <a href="/schedule">Schedule</a>
                </li>
                <li>
                  <a href="/tournament">Tournament</a>
                </li>
                <li>
                  <a href="/roster">Roster</a>
                </li>
                <li>
                  <a href="/bannersponsors">
                    <p>Banner</p>
                    <p>Sponsorship</p>
                  </a>
                </li>
                <li>
                  <a href="/coaches">Coaches</a>
                </li>
                <li>
                  <a href="https://www.ladylobossoccer.com">Lobo Girls</a>
                </li>
              </>
            )}
          </ul>

          {barToggle === "less" && mobile && (
            <div className="menu">
              <ul className="chooser">
                <li>
                  <a href="/alumni">Alumni Soccer Records</a>
                </li>
                <li>
                  <a href="/photos">Alumni Photos</a>
                </li>
                <li>
                  <a href="/schedule">Schedule</a>
                </li>
                <li>
                  <a href="/tournament">Tournament</a>
                </li>
                <li>
                  <a href="/roster">Roster</a>
                </li>
                <li>
                  <a href="/bannersponsors">Banner Sponsorship</a>
                </li>
                <li>
                  <a href="/coaches">Coaches</a>
                </li>
                <li>
                  <a href="https://www.ladylobossoccer.com">Lobo Girls</a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
