import React, { Component } from "react";
import "../secondarystyles.css";

export default class BannerSponsors extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <h2>Soccer Banner Sponsors</h2>
        </div>
        <div className="card_centerer">
          <img
            className="larger_img"
            src="/Images/Banners/banner-1.jpg"
            alt=""
          />
          <img
            className="larger_img"
            src="/Images/Banners/banner-2.jpg"
            alt=""
          />
        </div>
        <div className="card_centerer">
          <img
            className="larger_img"
            src="/Images/Banners/banner-3.jpg"
            alt=""
          />
          <img
            className="larger_img"
            src="/Images/Banners/banner-4.jpg"
            alt=""
          />
        </div>
        <div className="card_centerer">
          <img
            className="larger_img"
            src="/Images/Banners/banner-5.jpg"
            alt=""
          />
          <img
            className="larger_img"
            src="/Images/Banners/banner-6.jpg"
            alt=""
          />
        </div>
        <div className="card_centerer">
          <img src="/Images/Banners/banner-7.jpg" alt="" className="larger_img" />
        </div>
        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              Your company logo prominently positioned at the Lobo Soccer Field
              and other media outlets.
            </p>
            <br />
            <p>
              If you want your business signs around the grass fields and
              parking lots, please contact us.
            </p>
            <br />
            <p>
              More info and mail-in form{" "}
              <a href="/Files/Banner.pdf" target="about:blank">
                here
              </a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
