import React, { Component } from "react";

export default class Roster extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <h2>Roster: Coming Soon</h2>
        </div>
      </section>
    );
  }
}
