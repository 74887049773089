import React, { Component } from "react";
import "../secondarystyles.css";

export default class Coaches extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <h2>Coaches</h2>
        </div>
        <div className="card_centerer">
          <a href="/Files/Manual.pdf" target="about:blank">Coaches Manual</a>
        </div>
        <div className="card_centerer">
          <img src="./Images/wright1.jpg" alt="" />
          <img src="./Images/wright2.png" alt="" />
        </div>
        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              Coach Wright and Coach Fenet Wright have been at Longview since
              August of 2002. James is from Grimsby, England, and Margaret is
              from Lake Charles, Louisiana. James was the former girls coach at
              Pine Tree I.S.D. before moving to Longview, and Margaret was the
              assistant men&#39;s and women&#39;s coach at Centenary University
              in Shreveport. Margaret also coached the Longview Soccer Club
              before accepting the coaching job at Longview High School. Both
              James and Margaret played professional in the United States
              International Soccer League and have three children, Blaine, Emma,
              and Reece.
            </p>
            <br />
            <p>
              Over the past 20 + year, these two coaches have helped to build
              the soccer program into a consistently winning team and to help
              turn the young men that come through the program into college
              players with opportunities to continue to learn and build on their
              success.
            </p>

            <br />
            <p>Margaret Wright: mwwright@lisd.org</p>
            <p>James Wright: jawright@lisd.org</p>
          </div>
        </div>
        <div className="card_centerer">
          <img src="./Images/carpenter.jpg" alt="" />
        </div>
        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              Coach Carpenter graduated from Longview High School in 1988. He
              played college football for Pittsburg State University before
              earning a BBA from the University of North Texas in 1992. Coach
              Carpenter has been coaching football since 1993. Coach Carpenter
              is married to Irene and they have two sons, Armando and David.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
