import Navbar from "./Pages/Navbar/Navbar.js";
import Home from "./Pages/Home/Home.js";
import Footer from "./Pages/Footer/Footer.js";

import Buy from "./Pages/Buy/Buy.js";
import Donate from "./Pages/Donate/Donate.js";
import Alumni from "./Pages/Secondaries/Alumni/Alumni.js";
import Coaches from "./Pages/Secondaries/Coaches/Coaches.js";
import Roster from "./Pages/Secondaries/Roster/Roster.js";
import Schedule from "./Pages/Secondaries/Schedule/Schedule.js";
import BannerSponsors from "./Pages/Secondaries/Team Info/BannerSponsors.js";
import Tournament from "./Pages/Secondaries/Tournament/Tournament.js";
import Socials from "./Pages/Secondaries/Socials/Socials.js";
import Photos from "./Pages/Secondaries/Photos/Photos.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Navbar />
      <section className="large-content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/buy" element={<Buy />} />

          <Route path="/donate" element={<Donate />} />

          <Route path="/alumni" element={<Alumni />} />

          <Route path="/coaches" element={<Coaches />} />

          <Route path="/roster" element={<Roster />} />

          <Route path="/schedule" element={<Schedule />} />

          <Route path="/bannersponsors" element={<BannerSponsors />} />

          <Route path="/tournament" element={<Tournament />} />

          <Route path="/socials" element={<Socials />} />

          <Route path="/photos" element={<Photos />} />
        </Routes>
      </section>

      <Footer />
    </Router>
  );
}

export default App;
