import "./Buy.css";

function Buy() {
  return (
    <section className="buy">
      <div className="buycard">
        <h2>Coming Soon</h2>
        <p>Lobos Merchandise coming soon...</p>

        <button className="checkoutButton">Checkout</button>
      </div>
    </section>
  );
}
export default Buy;
