import React, { Component } from "react";
import "../secondarystyles.css";

export default class Alumni extends Component {
  render() {
    return (
      <section className="secondary-wrapper">
        <div className="card_centerer">
          <div className="large_secondary_card">
            <p>
              <a
                href="https://lobohistory.com/othersports/soccerboys/main.html"
                target="about:blank"
              >
                Other history
              </a>
            </p>
            <br />
            <p>
              Historical PowerPoint:{" "}
              <a href="https://docs.google.com/presentation/d/1aD6vFrLIhmmjE8wMxiiVd5eUDnGQawDD/edit?usp=sharing&ouid=107875182396900085597&rtpof=true&sd=true" target="about:blank">
                view
              </a>
            </p>
          </div>
        </div>
        <div className="card_centerer">
          <h2>Team Records</h2>
        </div>
        <div className="card_centerer">
          <table>
            <tr>
              <th>Since 1986</th>
              <th></th>
              <th>Year</th>
            </tr>
            <tr>
              <td>Most Goals, Game</td>
              <td>22</td>
              <td>1992 vs Texas High</td>
            </tr>
            <tr>
              <td>Most Goals, Season</td>
              <td>81</td>
              <td>1994</td>
            </tr>
            <tr>
              <td>Fewest Goals Allowed, Season</td>
              <td>15</td>
              <td>1992</td>
            </tr>
            <tr>
              <td>Most Games Won, Season</td>
              <td>20</td>
              <td>2021</td>
            </tr>
            <tr>
              <td>Least Games Lost, Season</td>
              <td>3</td>
              <td>1986, 89, 90, 93 & 94</td>
            </tr>
            <tr>
              <td>Longest Winning Streak</td>
              <td>12</td>
              <td>1994</td>
            </tr>
            <tr>
              <td>Most Consecutive Shutouts</td>
              <td>8</td>
              <td>2004</td>
            </tr>
          </table>
        </div>

        <div className="card_centerer">
          <h2>Individual Records</h2>
        </div>
        <div className="card_centerer">
          <table>
            <tr>
              <th>Since 1986</th>
              <th colSpan={2}></th>
              <th>Year</th>
            </tr>
            <tr>
              <td>Most Goals, Game</td>
              <td>5</td>
              <td>C. Fuller</td>
              <td>1992</td>
            </tr>
            <tr>
              <td>Most Goals, Season</td>
              <td>28</td>
              <td>Israel Madrid</td>
              <td>2012</td>
            </tr>
            <tr>
              <td>Most Goals, Career</td>
              <td>69</td>
              <td>Jesus Galvan</td>
              <td>2017-20</td>
            </tr>
            <tr>
              <td>Most Shutouts, Season</td>
              <td>14</td>
              <td>E. Torres</td>
              <td>2022</td>
            </tr>
            <tr>
              <td>Most Shutouts, Career</td>
              <td>38</td>
              <td>W. Briley</td>
              <td>2001-04</td>
            </tr>
            <tr>
              <td>Most Assists, Season</td>
              <td>20</td>
              <td>J. Torres</td>
              <td>2003</td>
            </tr>
            <tr>
              <td>Most Assists, Career</td>
              <td>39</td>
              <td>J. Torres</td>
              <td>2003-04</td>
            </tr>
          </table>
        </div>

        <div className="card_centerer">
          <h2>Individual Scoring - Season</h2>
        </div>
        <div className="card_centerer">
          <table>
            <tr>
              <th>Place</th>
              <th>Name</th>
              <th># of Goals</th>
              <th>Year</th>
            </tr>
            <tr>
              <td>1</td>
              <td>I. Madrid</td>
              <td>28</td>
              <td>2012</td>
            </tr>
            <tr>
              <td>2</td>
              <td>J. Galvan</td>
              <td>27</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>3</td>
              <td>I. Ponce</td>
              <td>27</td>
              <td>2006</td>
            </tr>
            <tr>
              <td>4</td>
              <td>I. Ponce</td>
              <td>24</td>
              <td>2007</td>
            </tr>
            <tr>
              <td>5</td>
              <td>J. Galvan</td>
              <td>23</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>6</td>
              <td>L.Perez</td>
              <td>20</td>
              <td>2014</td>
            </tr>
            <tr>
              <td>7</td>
              <td>M. Tidmore</td>
              <td>20</td>
              <td>2000</td>
            </tr>
            <tr>
              <td>8</td>
              <td>J. Galvan</td>
              <td>19</td>
              <td>2019</td>
            </tr>
            <tr>
              <td>9</td>
              <td>H. Rios</td>
              <td>19</td>
              <td>2009</td>
            </tr>
            <tr>
              <td>10</td>
              <td>C. Hargrave</td>
              <td>19</td>
              <td>1994</td>
            </tr>
            <tr>
              <td>11</td>
              <td>J. Nethery</td>
              <td>19</td>
              <td>1989</td>
            </tr>
            <tr>
              <td>12</td>
              <td>R. Patino</td>
              <td>17</td>
              <td>2017</td>
            </tr>
            <tr>
              <td>13</td>
              <td>J. Torres</td>
              <td>17</td>
              <td>2003</td>
            </tr>
            <tr>
              <td>14</td>
              <td>S. Williams</td>
              <td>17</td>
              <td>1994</td>
            </tr>
            <tr>
              <td>15</td>
              <td>J. Lopez</td>
              <td>16</td>
              <td>2004</td>
            </tr>
            <tr>
              <td>16</td>
              <td>JC Serber</td>
              <td>16</td>
              <td>2003</td>
            </tr>
            <tr>
              <td>17</td>
              <td>L. Perez</td>
              <td>15</td>
              <td>2013</td>
            </tr>
            <tr>
              <td>18</td>
              <td>J. Perez</td>
              <td>15</td>
              <td>2011</td>
            </tr>
            <tr>
              <td>19</td>
              <td>I. Ponce</td>
              <td>15</td>
              <td>2005</td>
            </tr>
            <tr>
              <td>20</td>
              <td>R. Nicholson</td>
              <td>15</td>
              <td>2000</td>
            </tr>
            <tr>
              <td>21</td>
              <td>J. May..</td>
              <td>15</td>
              <td>2000</td>
            </tr>
            <tr>
              <td>22</td>
              <td>C. Fuller</td>
              <td>15</td>
              <td>1993</td>
            </tr>
            <tr>
              <td>23</td>
              <td>J. Torres</td>
              <td>14</td>
              <td>2004</td>
            </tr>
            <tr>
              <td>24</td>
              <td>J. Gimenez</td>
              <td>13</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>25</td>
              <td>I. Madrid.</td>
              <td>13</td>
              <td>2011</td>
            </tr>
            <tr>
              <td>26</td>
              <td>C. Fuller</td>
              <td>13</td>
              <td>1992</td>
            </tr>
            <tr>
              <td>27</td>
              <td>A. Rojas-Monsivais</td>
              <td>12</td>
              <td>2021</td>
            </tr>
            <tr>
              <td>28</td>
              <td>R. Nicholson</td>
              <td>12</td>
              <td>2001</td>
            </tr>
            <tr>
              <td>29</td>
              <td>G. Jimenez</td>
              <td>12</td>
              <td>1994</td>
            </tr>
            <tr>
              <td>30</td>
              <td>E. Jacobs</td>
              <td>12</td>
              <td>1987</td>
            </tr>
            <tr>
              <td>31</td>
              <td>A. Flores</td>
              <td>11</td>
              <td>2022</td>
            </tr>
            <tr>
              <td>32</td>
              <td>F. Acosta</td>
              <td>11</td>
              <td>2021</td>
            </tr>
            <tr>
              <td>33</td>
              <td>R. Patino</td>
              <td>11</td>
              <td>2016</td>
            </tr>
            <tr>
              <td>34</td>
              <td>I. Valle</td>
              <td>11</td>
              <td>2001</td>
            </tr>
            <tr>
              <td>35</td>
              <td>G. Jimenez</td>
              <td>11</td>
              <td>1992</td>
            </tr>
            <tr>
              <td>36</td>
              <td>T. Dauper</td>
              <td>11</td>
              <td>1990</td>
            </tr>
            <tr>
              <td>37</td>
              <td>J. Lenhart</td>
              <td>11</td>
              <td>1990</td>
            </tr>
            <tr>
              <td>38</td>
              <td>E. Jacobs</td>
              <td>11</td>
              <td>1989</td>
            </tr>
            <tr>
              <td>39</td>
              <td>J. Baird</td>
              <td>11</td>
              <td>1986</td>
            </tr>
            <tr>
              <td>40</td>
              <td>K. Willyard</td>
              <td>11</td>
              <td>1986</td>
            </tr>
            <tr>
              <td>41</td>
              <td>P. Kelsey</td>
              <td>10</td>
              <td>2022</td>
            </tr>
            <tr>
              <td>42</td>
              <td>F. Hernandez</td>
              <td>10</td>
              <td>2021</td>
            </tr>
            <tr>
              <td>43</td>
              <td>A. Rojas-Monsivais</td>
              <td>10</td>
              <td>2020</td>
            </tr>
            <tr>
              <td>44</td>
              <td>A. Rojas-Monsivais</td>
              <td>10</td>
              <td>2019</td>
            </tr>
            <tr>
              <td>45</td>
              <td>M. Maia</td>
              <td>10</td>
              <td>1999</td>
            </tr>
            <tr>
              <td>46</td>
              <td>J. May</td>
              <td>10</td>
              <td>1998</td>
            </tr>
            <tr>
              <td>47</td>
              <td>G. Jimenez</td>
              <td>10</td>
              <td>1991</td>
            </tr>
          </table>
        </div>

        <div className="card_centerer">
          <h2>Goal Keeper Records</h2>
        </div>
        <div className="card_centerer">
          <table>
            <tr>
              <th>Most Shutouts: Season</th>
              <th>Since 1986</th>
              <th>Year</th>
            </tr>
            <tr>
              <td>14</td>
              <td>E. Torrez</td>
              <td>2022</td>
            </tr>
            <tr>
              <td>12</td>
              <td>W. Briley</td>
              <td>2004</td>
            </tr>
            <tr>
              <td>12</td>
              <td>R. Torrez</td>
              <td>2018</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Most Shutouts: Career</th>
              <th>Since 1986</th>
              <th>Year</th>
            </tr>
            <tr>
              <td>38</td>
              <td>W. Briley</td>
              <td>2001-04</td>
            </tr>
            <tr>
              <td>35</td>
              <td>R. Torrez</td>
              <td>2017-20</td>
            </tr>
          </table>
        </div>

      </section>
    );
  }
}
