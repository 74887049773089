import "./Footer.css";

function Footer() {
  return (
    <footer>
      <svg
        id="topcurve"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#e1dcdb"
          fillOpacity="1"
          d="M0,288L60,240C120,192,240,96,360,90.7C480,85,600,171,720,208C840,245,960,235,1080,197.3C1200,160,1320,96,1380,64L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <div className="card_centerer">
        <ul className="horizontal_list">
          <li className="social_svg">
            <a href="https://www.facebook.com/LongviewHighSchoolLoboSoccer">
              <img src="./Images/facebook-svg.svg" alt="" />
            </a>
          </li>
          <li className="social_svg">
            <a href="https://www.instagram.com/lobos.soccer/">
              <img
                src="./Images/instagram-svg.svg"
                alt=""
                style={{ fill: "white" }}
              />
            </a>
          </li>
          <li className="social_svg">
            <a href="https://twitter.com/LongviewLoboSoc">
              <img src="./Images/twitter-svg.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <p>Banner Picture Cred: Longview High School Lobo Pictures /</p>
      <p>Kyle pickett and Ginger Gannon</p>
      <p>Other Picture Cred: Todd Kelsey</p>
    </footer>
  );
}

export default Footer;
